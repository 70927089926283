.auth-landing-page {
    z-index: 0;
    height: 100vh;
    background-color: #f5f5f5;
    /* display: flex;
    flex-direction: column;
    justify-content: center;
    flex-wrap: wrap; */
    overflow: hidden;
    justify-content: flex-end;

    .row {
        --bs-gutter-x: 0 !important;
        width: 100%;
        top: 45%;
        z-index: 2;
        position: absolute;
    }

    footer {
        height: auto !important;
        z-index: 3;
        position: absolute;
        bottom: 0;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: end;
                /*  */
                        p {
                            position: relative;
                            top: 0.5rem;
                        }
            
                        p,
                        .logo-container {
                            font-size: 0.6rem !important;
                        }
            
                        a {
                            height: 75% !important;
                        }
                    
            
                    .row {
                        top: 35%;
                    }
        a img {
            height: 100%;
        }

        .logo-container {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
}

.background-gradient {
    z-index: 1;
    position: absolute;
    top: 0;
    height: 100vh;
    width: 100vw;
    background: linear-gradient(to top, white 50%, transparent);
}

.landing-page-picture {
    position: absolute;
    top: 0;
    height: 55vh;
    margin: 0;
    width: 100%;
    overflow: hidden;
    img {
        height: 100%;
        width: 100%;
        -webkit-filter: grayscale(1);
        filter: grayscale(1);

    }
}

main:has(.auth-landing-page) {
    padding: 0;
    height: 100vh;
    margin: 0 !important;
}

@media screen and (max-width: 576px) {

    .landing-page-picture {
        img {
            transform: scaleX(1.3);
        }
        overflow-x: hidden;
    }

    .background-gradient {
        background: linear-gradient(to top, white 55%, transparent);
    }
}

@media screen and (max-height: 700px) {

    .auth-landing-page {

        .row {
            top: 35%;
        }
    }

    .background-gradient {
        background: linear-gradient(to top, white 57%, transparent);
    }


}

@media screen and (min-height: 700px) {
    .background-gradient {
        background: linear-gradient(to top, white 51%, transparent);
    }

}