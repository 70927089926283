.header-admin {
	height: 50px;
	width: 100%;
	position: fixed !important;
	top: 0px;
	background-color: white;
	display: flex;
	box-shadow: 0px 1px 2px 0px rgba(60, 64, 67, 0.3), 0px 1px 3px 1px rgba(60, 64, 67, 0.15);
	align-items: center;
		#admin-nav-dropdown {
				font-size: 1rem;
				justify-content: flex-end;
				padding: 0;
				margin-right: .5rem;
				text-align: right;
				&:hover, &:focus {
					text-shadow: none;
				}
				& + .dropdown-menu {
					background-color: rgba(4, 30, 73, 0.7) !important;
					a {
						&:hover {
							background-color: transparent !important;
						}
						color: white;
					}
				}
			}
}
main:has(.admin-con) {
	background-color: var(--background-color);
	height: calc(100% - 50px);
	margin: 0;
	padding: 0;
	padding-top: 80px;
}
/* CLASS USED FOR CONTAINER ALIGNED FOR ADMIN SCREEN */
.admin-con {
  height: 100%;
  margin: 0;
  width: 100%;
}
@media screen and (min-width: 992px){
  .admin-con {
    width: calc(100% - 13rem) !important;
    margin-left: auto;
  }
}

.admin-navbar {
	height: 100%;
	width: 100%;
	padding: 0 2rem;
	justify-content: inherit;
	align-items: center;
}

.admin-navbar li {
	width: 100%;
	list-style: none;
}

.admin-navbar li h3,
.admin-navbar li svg {
	font-size: 1rem;
}

.navbar-collapse-admin {
	position: fixed;
	display: flex;
	flex-direction: row;
	top: 0px;
	left: 0;
	padding: 0 !important;
	height: 100%;
	background-color: #f3f7fd;
	width: 13rem;
	margin: 0;
	border-right: 1px solid rgba(4, 30, 73, 0.1) !important;
	align-items: flex-start;
	transition-duration: 1s;
	overflow: hidden;
	box-sizing: border-box;
	@media screen and (max-width: 991px) {
			& {
				justify-content: unset !important;
				width: 0 !important;
			}
	
			&.open {
				width: 260px !important;
				justify-content: unset !important;
				transition: 1s;
			}
		}
}

.showw {
	opacity: 1;
}
.header-admin .dropdown-menu {
	position: absolute;
	left: -5rem;
	background-color: var(--secondaryColor);
}

.admin-navbar-right {
	width: calc(100% - 13rem);
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-direction: row;
}
@media screen and (max-width : 992px) {
	.admin-navbar-right {
		width: 90%;
	}
}
.admin-navbar-right .admin-nav-title {
	width: auto;
	font-weight: 600;
	transform: translateY(20px);
	transition: opacity 0.15s, transform 0.15s;
	opacity: 0;
}

.admin-navbar-right .admin-nav-title.show {
	opacity: 1;
	transform: translateY(0);
	transition: opacity 0.5s, transform 0.5s;
}

.admin-navbar-right span {
	color: rgba(4, 30, 73, 0.7) !important;
}
.admin-navbar-right .sb-avatar.sb-avatar--text>* {
	background-color: transparent !important;
}

.admin-navbar h3 {
	margin: 0 16px;
	padding: 4px 8px 4px 0;
	font-size: 20px;
	transition-duration: 1s;
}

.behind {
	z-index: -5000;
}

.navbar-menu-Admin {
	position: absolute;
	top: 100px;
	left: -16px;
	height: 100vh;
	box-shadow: inset -20px 0px 20px 10px #00377a45;
	padding: 0 !important;
	width: 72px;
}

.admin-search {
	display: flex;
	margin: 0 16px;
	padding: 4px 8px 4px 0;
	flex-direction: row;
	align-content: center;
	justify-content: center;
	vertical-align: middle;
	align-items: center;
	width: 11rem;
	font-size: 20px;
	height: 32px;
}

.admin-toggler {
	margin-left: -1rem;
}

.mobile-menu-Admin .nav-link {
	color: var(--btnFontColor) !important;
}

@media screen and (max-width: 992px) {
	.header-admin img {
		height: 60px;
	}
	.admin-navbar li h3,
	.admin-navbar li svg {
		font-size: 0.97rem;
	}
	.header-admin {
		width: 100%;
		margin: 0;
		padding-top: 0.3rem;
	}
}

@media screen and (min-width: 992px) {
	.admin-toggler {
		visibility: hidden;
	}
}
