.class-card {
    display: flex;
    height: 33%;
    border-bottom: 1px solid #E8E6E6;
    width: 100%;
    transition: 0.1s;

    &.selected .class-actions button {
        background-color: #c5c4c4;
        transition: 0.1s;
    }

    /* actions displaying in the side of the card */
    .class-actions {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 30%;
        margin-top: 1rem;
        flex-direction: column;

        span {
            height: fit-content;
            display: block;
            width: 100%;
            font-size: 1rem;
            font-weight: 500;
        }

        span.coach {
            font-size: 1.1rem;
            font-weight: 600;
            text-align: left;
        }
    }

    /* wod image displaying to the side of the card on lower resolutions */
    .class-image {
        width: 60%;
        padding: .2rem;
        display: flex;
        align-items: center;

        .img {
            height: 70%;
            aspect-ratio: 1/1;
            img {
                border-radius: 10px;
                height: 100%;
                width: 100%;
            }
        }
    }

    .class-data {
        height: 100%;
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: center;

        .time {
            flex: 1;
            align-items: flex-end;
            text-align: center;
            font-weight: 300;
            font-size: 1rem;
        }

        .name {
            max-width: 100%;
            button {
                font-size: 42px;
                font-weight: 800;
                background-color: transparent;
                border: none;
                text-transform: uppercase;
                overflow: hidden;
                text-overflow: ellipsis;
                flex: 1;
                @media screen and (max-width: 576px) {
                    & {
                        white-space: nowrap;
                        
                    }
                }
            }
        }

        .coach {
            align-items: flex-start;
            flex: 1;
        }

        .availability {
            flex: 2;
            display: block;
            flex-direction: row;

            .available-capacity {
                width: 100%;
                text-align: center;
                font-weight: 400;
                font-size: 1.1rem;
            }

            .progress {
                background-color: #D9D9D9;
            }

            .progress-bar {
                background-color: black;
            }
        }
    }

    &.selected {
        background-color: #e8e6e6d1;
        transition: 0.1s;
    }

    .time,
    .name,
    .coach {
        justify-content: center;
        display: flex;
    }

    .attend-button {
        background-color: #E8E6E6;
        border: none;
        margin-right: auto;
        width: 80%;
        border-radius: 10px;
        height: fit-content;
    }
    .attend-button:disabled {
        background-color: #E8E6E6 !important; 
    }
    
    .attend-button:disabled:hover {
        background-color: #E8E6E6 !important; 
        color: rgba(16, 16, 16, 0.3) !important;
    }

    /* media queries */


    @media screen and (max-width: 768px) {
        .name {
            button {
                font-size: 36px !important;
            }
        }
    }

    @media screen and (max-width: 576px) {
        width: 100%;

        .availability {
            .available-capacity {
                font-size: 1rem !important;
            }
        }

        .time {
            font-size: .9rem;
        }

        .name {
            button {
                font-size: 26px !important;

            }
        }

    }

    .attend-button {
        width: fit-content;
        padding: .25rem .5rem;
        margin: .25rem auto;
        font-size: 0.85rem;
        transition: 0.15s;
    }
    .attend-button:hover {
        background-color: black;
        transition: 0.15s;
    }

}