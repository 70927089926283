.profile-event-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem;
  border-bottom: 1px solid #e0e0e0;
  width: 100%;
}

.profile-event-info {
  display: flex;
  align-items: center;
  width: 70%;
}


.date-circle {
  display: flex;
  flex: 0 0 20%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #f2f2f2;
  border-radius: 50%;
  height: 50px !important;
  max-width: 50px !important;
  text-align: center;
  margin-right: 0px;
  margin-left: 0.2rem;
  align-self: baseline;
}

.profile-date-number {
  font-size: 16px;
  font-weight: bold;
}

.profile-date-day {
  font-size: 12px;
  color: #888;
}

.profile-event-details {
  flex: 0 0 80%;
    display: flex;
    flex-direction: column;
    text-align: center;
}

.profile-event-time {
  font-size: 14px;
  color: #555;
}

.event-title {
  font-size: 16px;
  font-weight: bold;
}

.event-coach {
  font-size: 12px;
  color: #777;
}

.class-button-container {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 0.2rem;
}

.class-attend-button {
  font-family: 'Inter', sans-serif;
  border: none;
  background-color: #E8E6E6;
  font-size: 14px;
  cursor: pointer;
  border-radius: 10px;
  height: 25px;
  width: fit-content;
  padding: 0.2rem 1rem;
  white-space: nowrap;
  text-align: center;
  justify-content: center !important;
  justify-self: center;

  .event-selected{
    font-size: 10px !important;
  }
}

.attend-button:hover {
  background-color: black;
  color: white;
}

.event-image-container {
  flex: 0 0 30%; 
  flex-shrink: 0;
  display: flex;
  justify-content: center;

}

.event-image-container img {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 8px;
}