#landing-page-screen {
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    filter: grayscale(1);
    scroll-snap-type: y mandatory;
    scroll-behavior: smooth;

    section {
        scroll-snap-align: start;
        scroll-snap-stop: always;
        background-position-x: center;
        background-size: cover;
        width: 100vw;
        height: 100vh;
        button {
            z-index: 1000;
            background-color: #D9D9D94F;;
            color: white;
            text-transform: uppercase;
            border: none;
            padding: .5rem;
            font-weight: 600;
            border-radius: 5px;
        }
        a {
            text-transform: uppercase;
            font-weight: 300;
            color: rgba(255, 255, 255, 0.764);
            z-index: 1000;
            font-size: 1.2rem;
        }
        .section-overlay {
            width: 100vw;
            height: 40vh;
            margin-top: 60vh;
            position: absolute;
            z-index: 1;
            background: linear-gradient(to top, rgb(0, 0, 0) 20%, transparent);
        }

        .section-top-overlay {
            width: 100vw;
            height: 20vh;
            margin-top: -1rem;
            position: absolute;
            z-index: 1;
            background: linear-gradient(to bottom, rgb(0, 0, 0) 10%, transparent);
        }
    }

    .short-caption,
    .long-caption,
    .caption {
        z-index: 1000;
    }

    @media screen and (max-width: 576px) {
        p.short-caption {
            display: block;
        }

        p.long-caption {
            display: none;
        }
    }

    @media screen and (min-width: 576px) {
        p.short-caption {
            display: none;
        }

        p.long-caption {
            display: block;
        }
    }

    .body {
        position: relative;
        left: 3%;
        bottom: 10%;
        color: white;
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: end;
        align-items: start;

        h1,
        h2,
        h2 {
            z-index: 1000;
            text-transform: uppercase;
        }

        /* &>* {
            margin-left: 2.5%;
        } */

        p {
            font-weight: 500;
            font-size: 20px;
            word-wrap: break-word;
            max-width: calc(100% - 4rem);
        }

        h1 {
            font-size: 75px;
            margin-bottom: 0;
            font-weight: 600;
        }

        h2 {
            margin-top: -0.5rem;
            font-size: 60px;
            font-weight: 600;
        }

        h3 {
            font-size: 30px;
            font-weight: 400;
        }

        @media screen and (max-width: 576px) {
            h1 {
                font-size: 60px;
                margin-bottom: 0;
                font-weight: 600;
            }

            h2 {
                margin-top: -0.5rem;
                font-size: 50px;
                font-weight: 600;
            }

            h3 {
                font-size: 20px;
                font-weight: 400;
            }

            p {
                font-size: 1rem;
                max-height: 30vh;
                text-overflow: clip;
                overflow: hidden;
            }

        }
    }
}