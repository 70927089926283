.booking-screen {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    padding-top: 0;
    .datepicker-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        height: 25%;
        width: 100%;
        border-bottom: 1px solid #E8E6E6;
        padding-right: 150px;
        padding-bottom: 0.5rem;
        flex: 1;
        @media screen and (max-width: 768px) {
            padding-right: 0;
            
        }
        .categories {
            display: flex;
            width: 100%;
            justify-content: start;
            align-items: end;
            padding: 0 0.2rem;

            .badge {
                background-color: transparent !important;
                border: none;
                width: fit-content;
                height: fit-content;
                margin-right: 0.5rem;
                font-size: 1rem;
                transition: 0.2s;
                font-weight: 300;

                &.selected {
                    transition: 0.2s;
                    background-color: black !important;
                    color: white !important;
                }
            }

            @media screen and (max-width: 992px) {
                .badge {
                    margin-right: 0.1rem;
                }
            }
        }
    }
    .no-items-msg {
        background-color: #E8E6E6;
        padding: 1rem;
        font-size: 1.5rem;
        font-weight: 600;
        border-radius: 8px;
        color: rgba(0, 0, 0, 0.768) ;
    }
    .body {
        flex: 4;
        height: 75%;
        width: 100%;
        .classes-column {
            height: 100%;
            max-height: 100%;
            overflow-y: scroll;
            border-radius: 0;
            padding: 0;
            border-right: 1px solid #E8E6E6;
        }
    }
    .class-description {
        h1 {
            height: fit-content;
            text-align: center;
        }
        flex-direction: column;
        justify-content: flex-start;
        display: flex;
        flex-wrap: wrap;
        max-height: 100%;
        overflow-y: auto;
        .carousel.slide {
            .carousel-inner {
                height: 100%;
                width: 100%;
            }
            .carousel-item {
                height: 100%;
                width: 100%;
            }
            height: 60%;
            width: 100%;
        }
        .wod-image {
            height: 100%;
            width: 100%;
        }

    }
}