.user-booking-list {
  .custom{
    font-size: 16px;
    font-family: inherit;
    display: inline-flex;
    height: 21px;
  }
  
  .split-custom{
    border-bottom-width: 8px;
  }
  
  .dropdown-menu{
    inset: auto auto auto auto !important;
    transform: translate3d(1.8em, 39.75px, 0px) !important;
    position: absolute;
  }
}
  