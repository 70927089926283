.my-datetime-picker .react-datetime-picker__inputGroup {
	font-size: 12px;
	/* add any other styles you need to override here */
}
.my-datetime-picker .react-datetime-picker__wrapper {
	border: none;
}
.my-datetime-picker .react-datetime-picker__button > svg {
	width: 12px;
	height: 12px;
}
.rdrMonthAndYearWrapper {
	display: none;
}

.calendarDropdownItem {
	padding: 0.25rem 1rem !important;
}
.filterBtn {
}
