.package-screen{
  padding: 1.5rem;
  padding-bottom: 90px !important;
}

@media (max-width: 575px) {
  .package-screen {
    padding-bottom: 125px !important;
  }
}
body:has(.navbar-collapse-admin) {
  .package-screen {
    padding-top: 0 !important;
    padding-left: 250px !important;
  }
    @media (max-width: 992px) {
      .package-screen {
        padding-left: 1.5rem !important;
      }
    }
}

