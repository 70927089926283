.wod-exercises{
    align-items: flex-start;
    button:has(svg) {
        background-color: transparent;
        border: none;
    }
    .form-control {
        border-color: #ced4da !important;
        padding-right: .75rem !important;
        background-image: none !important
    }

    .add-ex-btn {
        background-color: transparent;
        border: 1px solid rgb(206, 212, 218);
        color: rgb(86, 89, 92);
        border-radius: 4px;
        font-size: .9rem;
    }

    .is-invalid~.invalid-feedback,
    .is-invalid~.invalid-tooltip,
    .was-validated :invalid~.invalid-feedback,
    .was-validated :invalid~.invalid-tooltip {
        visibility: visible;
    }

    .exercise {
        .invalid-feedback {
            display: block !important;
            font-size: 0.8rem;
            min-height: 20px;
        }


    }

    .form-floating>.rbt input {
        padding-top: 1.625rem;
        padding-bottom: .625rem;
    }

    .form-floating>.rbt input {
        height: calc(3.5rem + 2px);
        line-height: 1.25;
    }

    .form-floating>.rbt~label {
        opacity: .65;
        transform: scale(.85) translateY(-.5rem) translateX(.15rem);
    }
}

.block-create-routine{
  width: 100% !important; 
  margin-bottom: 0.2rem !important; 
  padding: 0.2rem !important; 
}

.create-block-switch{
    border-radius: 5px;
    background: rgba(217, 217, 217, 0.30) !important;
    color: #000;
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    width: 176px;
height: 95%;
flex-shrink: 0;
justify-content: center;
align-items: center;
text-align: center;
display: flex;
}

.create-block-switch:disabled{
    color: black !;
 background: #dddddd !important; 

}

.create-block-switch:enabled{
 background: #f4f4f4 !important;
 color: black;
 /* background: #D9D9D9 !important;  */

}

.create-block-switch-container{
    width: 407px;
    height: 30px;
    flex-shrink: 0;
    border-radius: 8px;
background: rgba(217, 217, 217, 0.30);
display: flex;
align-items: center;
justify-content: center;
padding: 0.2rem;
margin-left: 1.5rem;

}

.typeof-level-switch-container{
    width: fit-content;
    height: 30px;
    flex-shrink: 0;
    border-radius: 8px;
background: rgba(217, 217, 217, 0.30);
display: flex;
align-items: center;
justify-content: center;
padding: 0.2rem;
margin-left: 1.5rem;

}

.typeof-level-switch{
    border-radius: 5px;
    background: rgba(217, 217, 217, 0.30) !important;
    color: #000;
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    width:min-content;
height: 95%;
flex-shrink: 0;
justify-content: center;
align-items: center;
text-align: center;
display: flex;
}