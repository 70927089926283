.cardTab.active {
  border: 2px solid #007bff;
  transition: border 0.3s;
}
.card-tabs {
  .content {
    display: none;
    border-radius: 0.65rem !important;
  }
  .content.active {
    display: block;
  }
}

.card-img-top{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 0;
  border-radius:  0.4rem;
  opacity: 0.9;        
  filter: blur(0.7px);     
  transition: 0.8s;    
}

.cardTab {
  border-radius: 0.45rem !important;
  position: relative;
  overflow: hidden;
  height: 100px !important;
}

.card-img-overlay {
  position: relative;
  z-index: 1;
  height: 100%;
}

.text-card-tab {
 text-decoration: solid;
 font-weight: 700;
}

.cards-cont{
  padding: 0;
  margin: 1.5rem 0rem 0rem 0rem !important;
justify-content: space-between;
align-items: center;
}

.card-cont{
  max-width: 300px;
  margin-bottom: 2rem;
  padding: 0.5rem;
}

@media screen and (max-width: 576px) {
  .cardTab {
      height: auto; 
  }

  .card-img-top {
      height: 100px; 
      object-fit: cover;
  }

  .text-card-tab {
      font-size: 1rem; 
  }

  .card-cont{
    margin-bottom: 1.2rem !important;
    margin: 0px;
    padding: 0.5rem;
    width: 50% !important;
  }
  .card-tabs {
    .tab-content{
      display: none !important;
    }
  }

  .cards-cont{
    margin-top: 0.5rem !important;
  }

  
}

/* Adjust margin and spacing */
@media screen and (max-width: 768px) {
  .container {
      padding: 1.3rem; 
      padding-top: 0rem;
      margin-top: 0 !important;
  }
}

