.row-between {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  padding: 10px;
  @media screen and (max-width: 768px){
    flex-wrap: wrap;
  }
}

.seller-input,
.coupon-input {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-right: 10px;
  flex: 1;
}

.coupon-container {
  display: flex;
  align-items: center;
}

.coupon-button {
  background-color: var(--secondaryColor);
  font-weight: 600;
  font-size: 0.85rem;
  height: 100%;
}

.card {
  margin-bottom: 20px;
}


#payment-method-container {
  position: relative;
  margin-bottom: 130px;
  overflow-y: auto;
  padding-bottom: 10px;
  .form-control:focus {
    border-bottom-color: #ccc !important;
  }
}

.halfCol{
  max-height: 750px;
}


.coupon-button-pm {
  position: relative;
  font-size: 1rem;
  font-weight: 500;
}

.spinner-position-pm {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
