/* RoutineForm.css */
.routine-form {
  font-family: Arial, sans-serif;
  padding: 0.3rem;
}

.routine-form h1 {
  font-size: 24px;
  margin-bottom: 20px;
  
}

.routine-header {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
}

.routine-header input {
  flex: 1;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.routine-header button {
  background-color: #ddd;
  border: none;
  padding: 8px 12px;
  border-radius: 4px;
  cursor: pointer;
}

.routine-block {
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 15px;
}

.routine-block h2 {
  font-size: 18px;
  margin-bottom: 10px;
}

.routine-block-details p {
  margin: 5px 0;
}

.routine-block button {
  background-color: #ddd;
  border: none;
  padding: 6px 10px;
  border-radius: 4px;
  cursor: pointer;
}

.add-routine-block {
  display: block;
  width: 100%;
  padding: 10px;
  background-color: #eee;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  margin-top: 20px;
}

/* Modal Styles */
.routine-modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.routine-modal {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  width: 400px;
}

.routine-modal h2 {
  font-size: 20px;
  margin-bottom: 20px;
}

.routine-modal input,
.routine-modal select {
  width: 100%;
  padding: 8px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.routine-modal-actions {
  display: flex;
  justify-content: space-between;
}
.routine-modal-actions button {
  padding: 8px 12px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.add-block{
  background-color: #b4b3b3;
  border-color: #ccc;
  margin-left: 0.3rem;
}

.next-step-button{
  margin-bottom: 0.2rem !important;
  float:right;
}

.routine-form-step {
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-bottom: 1rem;
  padding: 10px;
  overflow: auto;
}