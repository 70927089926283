.weekly-calendar {
	width: 100%;
	display: flex;
	flex-direction: column;
	overflow: scroll;
}

.grid-container {
	display: grid;
	grid-template-columns: 60px repeat(7, 1fr);
	grid-auto-rows: 50px;
}

.header-cell {
	font-weight: bold;
	text-align: center;
	border: 1px solid #ddd;
	padding: 10px;
	background-color: #f0f0f0;
}

.time-cell {
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 0.9em;
	border: 1px solid #ddd;
	background-color: #fff;
}

.hour-cell {
	border: 1px solid #ddd;
	position: relative;
	display: grid;
	grid-template-rows: repeat(4, 1fr); 
}

.quarter-hour-slot {
  position: relative; 
}

.class-quarter-block {
  position: absolute; 
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
  padding: 2px;
  overflow: visible;
}




.class-title {
  text-align: center;
  font-size: 0.6em;
  z-index: 10; 
  position: absolute; 
  top:0;
}


