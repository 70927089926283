.cart-screen {
    .login-prompt {
        display: flex;
        flex-direction: column;
        height: fit-content;
        background-color: white;
        border-radius: 5px;
        padding: 1rem;

        .body,
        .header,
        .footer {
            width: 100%;

        }

        .header,
        .footer {
            flex: 1
        }

        .footer {
            display: flex;
            justify-content: center;
            flex-wrap: wrap;

            span {
                font-size: 0.9rem;
                font-weight: 500;
            }

            a {
                font-size: 0.9rem;
                font-weight: 600;
            }
        }

        .body {
            flex: 3;
        }

        form {
            width: 100%;
        }

        h2 {
            width: 100%;
            text-align: center;
            font-size: 1.2rem;
            font-weight: 600;
        }

        button {
            border: none;
            height: 2.5rem;
            font-size: 0.88rem;
            font-weight: 600;
            padding: 0 1rem;
            border-radius: 25px;
        }

        .input-group {
            height: 3rem;

            span {
                svg {
                    font-size: 1.15rem;
                }
            }

            input,
            span {
                border-radius: 25px;
                height: 100%;
                border: none !important;
                border-left: none !important;
                font-weight: 350;
                font-size: 0.85rem;
                background-color: rgba(0, 0, 0, .03);
            }

            input::placeholder {
                font-size: 0.85rem;
                font-weight: 500;
            }

            span {
                height: 100%;
            }
        }
    }
}