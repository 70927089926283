.day-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    flex: 1;

    /* max-width: 60px;
    max-height: 60px; */
    .name {
        text-align: center;
        font-weight: 300;
        color: #000;
    }

    .date {
        text-align: center;
        font-weight: 200;
        color: #000;

    }

    .day-button {
        aspect-ratio: 1/1;
        border-radius: 50%;
        padding: 0.5rem;
        border: none;
        transition: 0.2s;

        &.selected {
            transition: 0.2s;
            background-color: black;

            .date {
                color: white;
                font-weight: 400;
            }
        }


    }

    @media screen and (min-width: 576px) {

        .name {
            font-size: .95rem;
        }

        .date {
            font-size: 1.45rem;
        }

    }



    @media screen and (max-width: 576px) {

        .name {
            font-size: .85rem;
        }

        .date {
            font-size: 1.15rem;
        }

    }

    @media screen and (max-width: 376px) {
        .day-button {
            padding: .3rem;
        }

        .name {
            font-size: .65rem;
        }

        .date {
            font-size: .95rem;
        }

    }
}