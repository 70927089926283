#place-order-screen .item-options {
    font-size: 0.8rem;
}

.confirm-order-button {
    position: relative;
    font-size: 1rem;
    font-weight: 500;
    width: 100%; 
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .button-spinner {
    vertical-align: middle;
  }
  