.variant-generation-wizard {
    width: 80vw;
    margin: auto;
    margin-top: 8%;
    max-width: 80vw;
    .form-control {
            font-size: 0.9rem;
            border: 1px solid #00000057 !important;
            border-radius: 0.18rem;
    }
    .form-floating {
        .form-control:not(select) {
            height: calc(2.7rem + 2px);
        }
        label {
            padding: .75rem .5rem;
            font-size: .85rem;
            color: rgb(33, 37, 41)
        }
    }
    .input-group {
        height: 3rem !important;
        .form-control {
            height: 100%;
            width: 90%;
        }
        button {
            cursor: pointer;
            border-color: #00000057 !important ;
            border-left: none !important;
            &:hover, &:active, &:focus {
                border-color: #00000057 !important ;
                background-color: transparent;
                box-shadow: none;
                outline: none;
            }
            svg {
                color: #00000057 !important;
            }
            width: 10%;
            height: 100%;
        }
    }
    .step-description {
        font-size: .9rem;
        margin: auto;
        font-weight: 400;
        margin-bottom: 1rem;
    }
    .step-content {
        margin-top: 0.75rem;
    }
}