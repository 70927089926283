.products {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding: 0.8rem !important;
}

.products .card-title .h5 {
  font-size: 1rem !important;
}

.products .card-body:hover {
  background-color: #dddddd59;
  transition: 0.5s;
}

.title-and-cart{
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding: 2rem 2rem 1rem 2rem;
}

.title {
  font-weight: 200 !important;
  font-size: 1.5rem;
  font-family: 'Inter', sans-serif;
  width: 75%;
  margin-left: 8px;
  color: 'black';
}

.cart{
  width: 20%;
  display: flex;
  align-items: center;
  background-color: aquamarine;
  padding: 0.5rem; /* Optional: adds space around icon */
}

.cart-home-screen{
  margin: 0 !important;
  padding: 0 !important;
}