#admin-footer {
	font-weight: 500 !important;
	width: 100%;
	height: 7rem;
	font-family: Ubuntu, sans-serif;
	color: rgb(4, 30, 73);
	font-size: 0.7rem;
}

#admin-footer .logo-container {
	height: 45px;
	font-size: 0.65rem;
}

#admin-footer .logo-container a {
	width: auto;
	height: 100%;
}

#admin-footer p {
	margin-top: -0.5rem;
	margin-right: 1rem;
	font-size: 10px;
	color: rgb(4, 30, 73);
	text-align: center;
}

.admin-footer img {
	float: none;
	width: 100px;
	margin: 5px;
	margin-bottom: 0;
}

@media screen and (max-width: 991px) {
	.admin-footer img {
		margin: auto;
	}
}