.email-config .btn {
	margin: 0;
}

.email-config .email {
	padding: 0.2rem 0.3rem;
}

.email-config {
	width: 100%;
	margin: 0;
}

.email-list {
	width: 68%;
}
.email-config .list-group {
	border-radius: 0;
}

.email-config .list-group-item {
	border-right: none;
	border-left: none;
	padding: 0;
}

.email-config .btn-plus {
	margin-left: 1rem ;
}

.email-config .btn-plus:hover,
.email-config .btn-plus:active,
.email-config .btn-plus:focus {
	margin: 0;
}

.btn-plus {
	outline: none !important;
}

@media (min-width: 992px) {
	.email-config {
		max-width: 720px;
	}
}

.email-config .btn-primary:hover {
	margin: 0;
}

@media (min-width: 720px) and (max-width: 991px) {
	.email-config {
		max-width: 620px;
	}
}

@media (min-width: 240px) and (max-width: 719px) {
	.email-config {
		max-width: 520px;
	}
}
