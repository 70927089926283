
.profile-events-list {
  width: 100%;
  padding: 10px;
  font-family: Arial, sans-serif;
  .line{
    width: 100%;
    border-bottom: 1px solid #E8E6E6;
    position: relative;
    margin: 0px;
    padding: 0px;
  }
}

.profile-events-header {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  font-family: 'Inter', sans-serif;
  font-size: 16px;
    font-weight: 200;
    padding-top: 0.2rem;

    span{
      padding-top: 0.2rem;
    }

}

.back-button {
  cursor: pointer;
  font-size: 20px;
  margin-right: 5px;
  padding-top: 0 !important;
}
/* 
h1 {
  font-size: 18px;
  margin: 0;
} */

.profile-events-tab-container{
  display: flex;
  flex-direction: column;

}

.profile-events-tabs {
  display: inline-flex;
  padding: 2px 10px;
  justify-content: start;
  align-items: center;
  gap: 10px;
  margin-bottom: 0.6rem;
  width: fit-content;
}

.profile-events-tab {
  font-family: 'Inter', sans-serif;
  flex: 1;
  border: none;
  background-color: white;
  font-size: 14px;
  cursor: pointer;
  border-radius: 10px;
  height: 25px;
  width: fit-content;
  padding: 0.2rem 1rem;
  white-space: nowrap;
  text-align: center;
  justify-content: center;

  .event-selected{
    font-size: 10px !important;
  }

}

.profile-events-tab.active {
  background-color: black;
  color: white;

}

.events-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.events-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #E8E6E6;
  border-radius: 5px;
  padding: 10px;
  width: 100%;
    border-bottom: 1px solid #E8E6E6;
    position: relative;
    margin: 0px;
    padding: 0px;
}

.card-content {
  display: flex;
  align-items: center;
  gap: 10px;
}

.classes-number {
  display: grid;
  text-align: center;
  background-color: white;
  border-radius: 5px;
  padding: 10px;
  min-width: 70px;
  font-size: 20px;
  strong{
    font-size: 26px;
  }
}

.classes-number span {
  font-size: 12px;
}

.events-info {
  font-size: 14px;
  color: #555;
  padding-top: 7px;

  p{
    margin-bottom: 8px;
  }
}

.button-container {
  display: flex;
  justify-content: center;
  margin-top: 40px; 
}

.obtener-nueva-button {
  background-color: #ababab;
  color: #000000;
  font-family: "Inter-Light", Helvetica;
  font-size: 16px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: normal;
  margin-bottom: -2.50px;
  margin-top: -4.50px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
  border: none;
  border-radius: 10px;
  padding: 7px 12px;
  cursor: pointer;
}

.obtener-nueva-button:hover {
  background-color: #282828; 
  color: white;
}

.card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around; 
}



.month-label {
  font-size: 18px;
  font-family: 'Inter', sans-serif; 
  font-weight: 200; 
  margin-right: 2rem; 
  padding-left: 1rem;
  cursor: pointer;
}

.upcoming-month-label {
  font-size: 16px;
  font-family: 'Inter', sans-serif; 
  font-weight: 200; 
  margin-right: 2rem; 
  padding-left: 1rem;
  padding-top: 0.4rem;
}



.calendar-icon:hover {
  color: #555; 
}

.month-picker-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  padding-top: 0.4rem;
  padding-bottom: 0.4rem;
  /* margin-top: 0.8rem; */
  /* margin-bottom: 0.5rem; */
}

.calendar-icon {
  cursor: pointer;
  position: relative;

  
}

.date-picker-container {
  position: absolute;
  top: 100%; /* Place the date picker below the icon */
  left: 0;
  z-index: 10;
  margin-top: 8px; /* Optional spacing */
  background-color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  width: max-content;
  margin-left: 0.5rem;
}
