#user-details-screen {
    /* .tab-container {
        width: 100%;
    } */
    padding: 0px !important;

    .user-basic-data {
        height: 15rem;
        width: 100%;
    }

   
}

#user-details-screen .tab-container{
    width: 100%;
}

.edit-membership, .user-membership-assigner {
    margin-top: 25%;
}

/* #user-details-screen  .table {
    thead th {
        &:first-of-type {
            border-left: 1px solid rgb(222, 226, 230);
        }
        &:last-of-type {
            border-right: 1px solid rgb(222, 226, 230);
        }
    }
} */
