.client-navbar {
	header {
		background-color: rgba(0, 0, 0, 0.78);
		height: 90px;
		display: flex;

		.logo {
			display: flex;
			align-items: center;
			max-height: 100%;
			padding-left: 4rem;
			a {
				height: 95%;
			}
			svg {
				font-size: 2rem;
			}
		}

		.logo,
		.options {
			width: 50%;
			height: 100%;
		}
	}

	.nav-toggler {
		background-color: rgba(217, 217, 217, 0.282);

		svg {
			color: white;
		}
	}

	.admin-navbar li {
		width: auto;
	}

	.options {
		.client-navbar-options {

			width: 100%;
			height: 100%;
			display: flex;
			justify-content: space-around;
			align-items: center;

			.nav-options {
				width: 80%;
				background: #D9D9D94F;
				flex-direction: row;
				display: flex;
				padding: 8px 5px;
				border-radius: .3rem;
				min-width: fit-content;

				a {
					font-size: 0.85em;
					font-weight: 600;
					display: flex;
					align-items: center;
					justify-content: center;
					padding: 0 0.5rem;
					color: rgba(4, 30, 73, 0.7) !important;
					transition: 0.1s;
					padding: 0.2rem;
					border-radius: 8px;
					margin: 0 0.2rem;
				}


			}

			@media screen and (max-width: 768px) {
				& {
					justify-content: flex-end;
				}

				.nav-options {
					display: none;
				}
			}
		}

		/* .nav-options {
					display: flex;
					margin: 0;
					gap: 0.2rem;
					color: rgba(4, 30, 73, 0.5) !important;
					justify-content: flex-start;
					cursor: pointer;
					flex-direction: column;
					align-content: center;
		
			
					a {
						font-size: 0.85em;
						font-weight: 600;
						display: flex;
						align-items: center;
						justify-content: center;
						padding: 0 0.5rem;
						color: rgba(4, 30, 73, 0.7) !important;
						transition: 0.1s;
						padding: 0.2rem;
						border-radius: 8px;
						margin: 0 0.2rem;
					}
				} */



		.nav-option {
			flex: 1;
			text-align: center;

			span {
				text-transform: uppercase;
				font-size: .85rem;
				color: white !important;
			}
		}

		.nav-option.focused {
			a {
				background-color: #ABABAB;
			}
		}

		@media screen and (max-width: 768px) {
			& {
				justify-content: FLEX-end;
			}

			.nav-options {
				display: none;
			}
		}

	}
}

.navbar-brand:not(.client-navbar) {
	justify-content: flex-start;
	margin: -0.7rem;
	margin: 0 auto;
	margin-left: 2rem;
}

@media screen and (min-width: 992px) {
	.mobile-menu {
		margin: 2rem 0 0 2.5rem;
	}
}