.membershipTemplate-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #E8E6E6;
  border-radius: 5px;
  padding: 10px;
  height: 100%;
  width: 100%;
}

.card-content {
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
}

.membershipTemplate-classes-number {
  display: grid;
  text-align: center;
  background-color: white;
  border-radius: 5px;
  padding: 10px;
  min-width: 70px;
  font-size: 20px;
}

.membershipTemplate-classes-number strong {
  font-size: 26px;
}

.membershipTemplate-classes-number span {
  font-size: 12px;
}

.membershipTemplate-info {
  font-size: 18px;
  color: #555;
  padding-top: 0px;
  width: 100%;
  margin-left: 2rem;
}

.membershipTemplate-info p {
  margin-bottom: 10px;
  text-align: left;
  font-size: 16px;
}

.template-button-container {
  display: flex;
  justify-content: center;
  margin-top: 2px; /* Adjust spacing as needed */
}

.add-to-cart-button {
  background-color: #000000; 
  color: white;
  font-family: "Inter-Light", Helvetica;
  font-size: 12px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: normal;
  margin-bottom: -2.50px;
  margin-top: -4.50px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
  border: none;
  border-radius: 10px;
  padding: 3px 12px;
  cursor: pointer;
}

.add-to-cart-button:hover {
  background-color: #ababab;
  color: #000000;
}