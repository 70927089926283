.class-data {
    .btn-danger {
        padding: 0.2rem 0.5rem;
        font-size: 0.8rem;
        width: 30px;
        height: 30px;
    }
        .weekday-btn {
            border-width: 0 !important;
        }
}