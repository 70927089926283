#checkout-navigator {
    position: fixed;
    justify-content: space-around;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    bottom: 0;
    left: 0;
    width: 100%;
    height: fit-content;
    padding: 0.5rem;
    box-shadow:0px -2px 4px 0px rgba(0,0,0,0.3);
    background-color: white;
    .checkout-stepper  {
        margin: 0;
        flex: 8 
    }
    .desktop {
        button {
            flex: 1;
            margin-bottom: 10px;
            scroll-behavior: auto;
        }
    }
    button {
        width: fit-content;
        margin: 0;
        justify-content: center;
        border: none;
        height: 2.5rem;
        align-items: center;
        font-size: 0.85rem;
        font-weight: 600;
        padding: 0 0.5rem;
        border-radius: 25px;

        span {
        }
        svg {
            margin:0  4px;
            font-size: 1.2rem;
        }
    }
}
