.nav-toggler {
	border: 1px solid rgba(0, 0, 0, .3);
	width: 3.5rem;
	height: 2.6rem;
	padding: 0.2rem;
	border-radius: 5px;
	opacity: 0.6;
	margin-right: 1rem;

	&.light {
		border: 1px solid rgba(255, 255, 255, .9);

		svg {
			color: rgba(255, 255, 255);
		}
	}

	button:focus,
	button:active {
		box-shadow: none !important;
		outline: 0;
	}

	svg {
		opacity: 1 !important;
		width: 100%;
		height: 100%;
		color: rgba(0, 0, 0, .8);

	}

	button {
		padding: 0;
		margin: 0;
		border-radius: 5px;
		opacity: 1;
		border: none;
		width: 100%;
		height: 100%;
		background-color: transparent;
	}

}