#profile-screen {
  margin-left: 5px;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-left: 0;

  .user-basic-data {
    padding: 0.25rem;
    padding-bottom: 1rem;
    width: 100%;
    height: auto !important;

    .basic-data {
      padding: 0;
    }
  }

  .header {
    min-height: fit-content;
    flex-wrap: wrap;
    flex: 1;
    display: flex;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);

  }

  .row {
    flex: 1;
  }

  .content {
    overflow: hidden;
    flex-wrap: wrap;
    justify-content: flex-start;
    width: 100%;
    flex: 11;
    display: flex;
    flex-direction: column;
  }

  .options {
    section {
      flex: 5
    }

    .notification-area {
      overflow: hidden;
      box-shadow: inset 0px 0 4px rgba(0, 0, 0, 0.2);
      margin-top: -52px;
      overflow: hidden;
      z-index: 1000;
      transition: margin-right 0.4s ease-in-out;
      flex: 3;
      padding: 1rem;
      margin-right: -100%;
      background-color: #F7F6F6;
      height: calc(100% + 52px);
      padding-top: 3.5rem;

      h4 {
        text-transform: uppercase;
        margin: 0;
      }

      section {
        width: 100%;
        margin-bottom: .35rem;
        .name {
          font-size: 1.05rem;
        }
        .description {
          font-size: 0.9rem;
          font-weight: 300;
        }

      }

      &.open {
        margin-right: 0;
        transition: margin-right 0.25s ease-in-out;
      }

      @media screen and (max-width: 768px) {
        position: fixed;
        left: 100vw;
        transition: left 0.25s ease-in-out;
        padding-top: 1rem;
        &.open {
          left: 0vw;
          transition: left 0.25s ease-in-out;
        }
      }
    }

  }

  .notification-btn {
    border-radius: .5rem;
    width: fit-content;
    padding: 8px 10px;
    z-index: 1001;
    box-shadow: 0px 2px 2px 0px #00000040;
    border: none;
    background-color: #8d8d8dbb;
    display: flex;
    align-items: center;
    transition: 0.1s;
    &:hover {
      transition: 0.1s;
      background-color: #8d8d8ddb;
    }

    span {
      color: white;
      border-radius: 11px;
      font-weight: 700;
      padding: 5px;
      font-size: 0.68rem;
      background-color: red;
      position: relative;
      left: 152%;
      margin-top: -13px;
      margin-left: -10px;
    }

    svg {
      color: white;
    }

    @media screen and (max-width: 768px) {
      position: static;
    }
  }
}




.profile-info {
  background-color: #d3d3d3 !important;
  margin: 0px;
  padding: 2rem;
  width: 100%;
  height: 100%;
}

.notification-row {
  width: 100%;
  padding: .75rem;
  padding-bottom: .5rem;
  height: fit-content;
  display: flex;
  justify-content: space-between;
  align-items: center;

  h4 {
    font-size: 1.5rem;
    margin-bottom: 0;
  }
  @media screen and (max-width: 768px) {
    justify-content: space-around;
    
  }

}


.user-det-con {
  width: 100%;
}

.card-tabs-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.card-tabs-container .col {
  padding: 0.5rem;
}

@media screen and (max-width: 768px) {
  #profile-screen {
    .header {
      height: 12%;
    }

    .content {
      height: 88%;
    }
  }
}

@media screen and (max-width: 576px) {
  #profile-screen {
    padding: 0 !important;

    .header {
      height: 15%;
    }

    .content {
      height: 85%;
    }

  }

  .perfil-con {
    margin-left: 0 !important;
    padding: 0.2rem;
  }

  .user-basic-data {
    padding: 0.5rem 1rem !important;
  }

  .col-cardtab {
    margin: 0;
    padding: 0;
  }

  .col-cardtab section {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .card-tabs-container .col {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .line {
    margin-bottom: 1rem;
  }

  #profile-screen {
    .text-center {
      padding: 0px !important;
    }

    h4 {
      color: hsl(0, 0%, 2%);
      font-family: 'Inter' sans-serif;
      text-transform: uppercase;
      font-style: normal;
      font-weight: 200;
      line-height: normal;
    }
  }
}

.perfil-con {
  width: 100% !important;
  margin-bottom: 2px !important;
  height: 100%;
  margin-top: 0px !important;

  .screen-locator {
    width: 100%;
    height: fit-content;

    span {
      display: flex;
      font-family: 'Inter', sans-serif;
      font-size: 0.95rem;
      font-weight: 200;
      padding: 0.25rem;
      margin-left: 0.2rem;
    }
  }
}