.modal-delete-user .modal-dialog {
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-delete-user .modal-dialog-centered {
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-delete-user {
  max-width: 100%;
  margin: 1.75rem auto;
}

@media (min-width: 576px) {
  .modal-delete-user {
    max-width: 500px;
  }
}

.modal-delete-user .button-user-container {
  display: contents;
  justify-content: center;
  align-items: center;
  width: 100%;
  
}

.modal-delete-user .modal-button {
  flex: 1;
  margin: 5px 10px;
  max-width: 150px; 
}

.button-user-container{
  display: flex;
}

.button-user-container .delete-button {
  background-color: #dc3545 !important;
  border: 1px solid #dc3545 !important;
  color: white !important;
  border-radius: 4px;
  cursor: pointer;
  text-decoration: none;
  margin-left: 2rem;
}
