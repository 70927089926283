.cart{
  background-color: white;
  display: flex;
  justify-content: flex-end;
  border: 0px;
}

.cart-button {
  display: flex;
  align-items: center;
  justify-content: center;
  border: none !important;
  background-color: #8D8D8D !important;
  border-radius: 8px;
  font-size: 1.7rem;
}


.cart-icon{
  font-size: 2rem !important;
  padding: 0.2rem;
}

.cart-button-container {
  position: relative; 
  border-radius: 8px;
  background-color: #8D8D8D !important;
}

.cart-badge {
  position: absolute;
  top: 0;  
  right: 0;
  transform: translate(-25%, -25%); 
  padding: 0.25em 0.4em; 
  background-color: red;
  color: white;
  border-radius: 50%;
  font-size: 0.6rem !important;
}