#side-nav-overlay {
    position: fixed;
    display: none;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    width: 100vw;
    /* Adjust the width as needed */
    height: 100vh;
    /* Adjust the height as needed */
    background-color: #00000031;
    /* Adjust the background color as needed */
    transition: 0.25s;
    z-index: 2000;
    /* transition: right 0.3s ease-in-out; */
}

#side-nav {
    z-index: 2001;
    position: fixed;
    background-color: white;
    height: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 100%;
    transform: translateX(0px);
    width: 550px;
    transition: 0.35s ease-out;
    background-color: #ABABAB;
    &.show {
        right: 0;
        transform: translateX(-550px);
        transition: 0.35s ease-out;
        box-shadow: -3px 0px 2px 0px rgba(60, 64, 67, 0.15);
    }
        .nav-option {
            padding: 0 1rem;
    
            display: flex;
            justify-content: flex-end;
    
            a {
                text-transform: uppercase;
                color: white;
                min-width: 40%;
                text-align: right;
                font-weight: 600;
                font-size: 24px;
                padding: 0.5rem;
                &:hover {
                    background-color: #E8E6E6 ;
                }
            }
        }
}

@media screen and (max-width: 762px) {
    #side-nav {
        width: 100%;

        &.show {
            right: 0;
            transform: translateX(-100vw);
        }
    }

}

#side-nav {
    &>* {
        display: flex;
    }
        a.focused {
            background-color: #E8E6E6;
        }
    .header {
        justify-content: flex-start;
        align-items: center;
        height: 60px;

        &.scrolled {
            box-shadow: 0px -1px 8px 0px #0000001a;
        }

        h1 {
            font-size: 1.4rem;
            font-weight: 400;
        }

        svg {
            color: white;
            font-size: 2.5rem;
        }
    }

    .body {
        height: calc(100% - 160px);
        overflow-y: scroll;
        flex-direction: column;


    }

    .footer {
        height: 100px;
        background-color: white;
        display: flex;
        box-shadow: 0px -1px 8px 0px #0000001a;
        justify-content: space-around;
        align-items: center;

        span {
            font-size: 1.2rem;
            font-weight: 300;
        }
    }

    .go-back-btn {
        background-color: transparent;
        text-decoration: underline;
    }

    .complete-purchase-btn {
        border: none;
        height: 40px;
        border-radius: 4px;
        color: white;
        font-weight: 500;
        background-color: var(--primaryColor);
        transition: 0.1s;

        &:hover {
            filter: brightness(95%);
            transition: 0.1s;
        }
    }
}