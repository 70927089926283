.auth-landing-page.sign-up-screen {
    .row {
        --bs-gutter-x: 0 !important;
        width: 100%;
        top: 43%;
        z-index: 2;
        position: absolute;
    }
}
@media screen and (max-height: 750px) {
    .auth-landing-page.sign-up-screen {
        .row {
            top: 30%;
        }
    }

}