/* RoutineViewer.css */
.routine-viewer {
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 8px;
  overflow: hidden;
  margin-top: 1.5rem;
}

.routine-viewer h2 {
  padding: 1rem;
  margin: 0;
}

.routine-viewer p {
  padding-left: 1.5rem;
  padding-bottom: 0.5rem;
  margin: 0;
}

.routine-viewer .routine-viewer-section {
  border-top: 1px solid rgba(0, 0, 0, 0.125);
  padding-top: 0.5rem;
  padding-left: 1.5rem;
}
.routine-viewer .routine-viewer-blocks {
  padding-top: 1.5rem;
  padding-left: 0;
}

.routine-viewer .routine-viewer-block {
  padding: 0 !important;
}

.routine-viewer .level-item {
  margin-bottom: 10px;
  padding-left: 2rem;
}

.routine-viewer .block-form {
  margin-bottom: 20px;
}

.read-only .form-control {
  background-color: #f8f9fa;
  border: none;
  cursor: not-allowed;
}