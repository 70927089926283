.quantity-picker {
    display: flex;
    align-items: center;
    border: 2px solid rgba(0, 0, 0, 0.685);
    border-radius: 1rem;
    height: 1.5rem;
    width: auto;
    button {
        height: 100%;
        margin: 0;
        background-color: transparent;
        border: none;
        display: flex;
        align-items: center;
    }
    svg {
        font-size: 1.1rem;
    }
    span {
        font-weight: 600;
        font-size: 0.85rem;
    }
    & > * {
        padding: 0.1rem 0.4rem;
        flex: 1
    }
}