:root {
	height: 100%;
	overflow: hidden;
}
body {
	overscroll-behavior: none;
	--mp-blue: #009ee3;
	height: 100%;
	width: 100%;
	margin: 0;
  padding: 0;
}

#root {
	height: 100%;
	width: 100%;
	overflow-y: auto;
	&>div {
		height: 100%;
	};
}
.pointer {
	cursor: pointer;
}

.client-container {
	height: 100%;
	width: 100%;
	padding-top:80px;
}
button.bg-transparent {
	border: none;
}

main {
	flex: 1;
	width: 100%;
	min-height: 100%;
	height: 100%;
	box-sizing: border-box;
	background-color: var(--background-color);
	width: auto;
	overscroll-behavior: none;
	padding-top: 80px;
	margin-left: 0;
	&:has(#landing-page-screen) {
		padding-top: 0;
	}
	/* padding: 1rem;  */
	/* OLD STYLES 
	padding: 1rem 5rem;
		flex: 1;
	margin-bottom: 150px;
	margin-top: 80px;
	padding: 1rem 4rem 5rem 0rem;
	margin-bottom: 20px !important;
	*/
}
main:has(.admin-con) {
	background-color: var(--background-color);
	height: calc(100% - 50px);
	margin: 0;
	padding: 0;
	
}
.admin-con {
	height: 100%;
	margin: 0;
	/* margin-top: 50px; */
	h1 svg {
		margin-right: 0.5rem;
	}
	.btn-primary,
	.btn-primary:hover,
	.btn-primary:active,
	.btn-primary:visited,
	.btn-primary:focus {
		background-color: #d7d5d591 !important;
		color: rgba(0, 0, 0, .607) !important;
		border: none !important;
		margin: 0 auto;
		outline: 0;
		transition: 0.1s;
	}
}
	    .no-items-msg {
	    	background-color: #E8E6E6;
	    	padding: 1rem;
	    	font-size: 1.5rem;
	    	font-weight: 600;
	    	border-radius: 8px;
	    	color: rgba(0, 0, 0, 0.768);
	    }

.btn-primary,
.btn-primary:hover,
.btn-primary:active,
.btn-primary:visited,
.btn-primary:focus {
	background-color: var(--secondaryColor) !important;
	color: var(--btnFontColor) !important;
	border: none !important;
	margin: 0 auto;
	outline: 0;
	transition: 0.1s;
}

.btn-primary:hover {
	filter: brightness(95%);
	transition: 0.1s;
}


@media screen and (min-width: 992px) {
	.admin-con {
		width: calc(100% - 13rem) !important;
		margin-left: auto !important;
	}
}
/* HEADER && NAVBAR */
header {
	display: flex;
	background-color: var(--primaryColor);
	justify-content: space-between;
	align-items: flex-end;
	position: fixed;
	z-index: 1000;
	box-shadow: inset -20px 0px 20px 10px var(--shadowNavColor);
}

header img {
	max-width: 6em;
}

.tableResponsive {
	width: -webkit-fill-available;
}

@media screen and (min-width: 992px) {
	.admin-con {
		margin-right: 0;
		display: flex;
		justify-content: center;
		max-width: calc(100% - 10rem);
		padding: 0.5 1rem !important;
		scroll-behavior: auto !important;
		margin-bottom: 3rem;
		padding-bottom: 2rem !important;
		max-width: -webkit-fill-available;
	}

	.admin-con>div {
		scroll-behavior: auto !important;
	}

	.map-cont {
		width: 100% !important;
	}
}

.admin-con {
	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	justify-content: flex-start;
	align-items: flex-start;
	scroll-behavior: auto !important;
	margin-bottom: 3rem !important;
	padding-bottom: 2rem !important;
	min-height: fit-content !important;
	max-width: -webkit-fill-available;
}

.navbar {
	width: 100vw;
	justify-content: center;
	padding-left: 2rem;
	height: 90px;
	align-items: flex-end;
	flex-direction: row;
}

.navbar ul {
	padding: 0.2rem 0.9rem;
}

.navbar li {
	margin-right: 0.25rem;
}

.navbar-brand {
	text-align: left;
}
button.transparent {
	background-color: transparent;
	border: none;
}

.socialNavbarLinks {
	justify-content: flex-end !important;
	padding-right: 0;
}

.socialNavbarLinks-top {
	justify-content: flex-end !important;
	padding-right: 0;
}

.socialNavbarLinks-top li {
	padding: 0.1rem 0 !important;
}

.nav {
	display: flex;
	flex-wrap: nowrap;
	padding-left: 0;
	margin-bottom: 0;
	margin: 0;
	padding: 0;
	list-style: none;
	align-content: flex-end;
	justify-content: flex-end;
	flex-direction: row;
	align-items: unset;
	width: fit-content;
}

.nav-link {
	display: -webkit-inline-box;
	align-items: center;
	padding: 4px 8px;
	color: var(--navFontColor) !important;
	height: 32px;
	padding-left: 0px;
	padding-right: 0.5rem;
	font-weight: 700;
	width: fit-content;
}

.ulnavbar li {
	margin: 0 1rem;
}

nav-link:focus,
.nav-link:hover {
	color: var(--background-color) !important;
	filter: drop-shadow(2px 2px 4px white);
}

.dropdown-menu {
	text-align: center;
}

.dropdown-item {
	width: 100%;
	text-align: left;
	padding: 0.3rem !important;
}

#basic-nav-dropdown {
	padding: 8px 8px 4px 15px;
	text-align: right;
	justify-content: flex-end;
	min-width: 120px;
}

.dropdown-item.active,
.dropdown-item:active {
	padding: 0.5rem 0.5rem;
}

.nav-item.dropdown {
	height: 32px !important;
	text-align: center !important;
}

header .badge {
	padding: 0.25em;
	margin-left: -6px;
}

/* END HEADER && NAVBAR */
/* ESTILOS GENERALES*/
.img-large {
	max-width: 100%;
}

.prod-page-img-lg {
	max-width: 40vw;
}

.img-thumbnail {
	height: 80px;
}

.small-container {
	max-width: 600px;
}

i {
	color: var(--IconsColor);
}

.col-12 {
	padding: 0px;
}



.eyeButton {
	text-shadow: 2px 2px 4px #000000;
	border-left: none;
	background: white;
	border-bottom: 1px solid var(--secondaryColor);
	padding: 0.5px 1rem;
	height: 32px;
	outline: 0 !important;
}

.eyeButton:focus {
	box-shadow: none !important;
}

.envios-img {
	height: 100%;
	object-fit: cover;
	object-position: center center;
}

.mobile {
	display: contents;
}

.mobile-menu {
	flex-direction: row;
	flex-wrap: inherit;
	width: fit-content;
}

li {
	margin: 0;
	padding: 4px 0px;
	width: unset;
}

.rightlinks {
	/* width: 20%; */
	justify-content: flex-end;
}



.social-links {
	display: flex;
	flex-direction: row !important;
	margin: 0;
	justify-content: center;
}

.social-links li {
	width: fit-content;

	padding: 0 1rem;
}

.navbar-expand-lg .navbar-nav {
	margin: 0 1rem;
}

.input-group {
	height: 32px;
	padding: 4px 8px;
}

.form-control {
	align-items: center;
	border-radius: .25rem;
}

.search-input {
	width: 10rem;
}

input:focus,
select:focus,
select,
input.form-control:focus {
	outline: none !important;

	outline-width: 0 !important;

	box-shadow: none;

	-moz-box-shadow: none;

	-webkit-box-shadow: none;
}

.listsAlert .alert {
	width: max-content;
}

@media screen and (min-width: 992px) {
	.prod-cat-col {
		width: 20% !important;
	}

	.nav-link .d-lg-block {
		padding-left: 2.5px !important;
	}
}

@media screen and (max-width: 991px) {
	.mobile-menu {
		flex-wrap: wrap;
		display: flex;
		width: 20vw;
		justify-content: flex-start;
		flex-direction: column;
		align-content: center;
	}

	.collapsable-navbar-extended .modal-content h3 {
		font-weight: 700 !important;
		color: rgb(232, 230, 227) !important;
	}

	.collapsable-navbar-extended .mobile-menu {
		margin: 2rem 0 0 2.5rem;
	}

	.navbar {
		width: 100vw;
		justify-content: center;
		height: 90px;
		align-items: flex-end;
	}
	.ulnavbar li {
		margin: 0 0.1rem;
	}

	.navbar-collapse .exit-menu {
		height: 1.8rem;
		border: none;
		background-color: transparent;
		color: rgb(255, 255, 255) !important;
	}

	.navbar-collapse {
		position: fixed;
		top: 0;
		left: 0;
		z-index: 1055;
		padding-left: 15px;
		padding-right: 15px;
		padding-bottom: 15px;
		height: 100%;
		width: 100%;
		background-color: var(--secondaryColor);
		/* box-shadow: inset -20px 0px 20px 10px #00377a45; */
	}

	.navbar-collapse {
		position: fixed;
		top: 80px;
		left: 0;
		padding-left: 15px;
		padding-right: 15px;
		padding-bottom: 15px;
		height: 100%;
		background-color: var(--secondaryColor);
		/* box-shadow: inset -20px 0px 20px 10px #00377a45; */
	}

	.rightlinks {
		width: 32px;
		justify-content: flex-end;
	}

	.navbar-collapse.show {
		left: 0;
		transition: left 300ms ease-in-out;
	}

	.navbar-collapse.collapsing {
		left: -75%;
		transition: height 0s ease;
	}

	.rightlinks {
		width: 32px;
		justify-content: flex-end;
	}

	.navbar-collapse.show {
		left: 0;
		transition: left 300ms ease-in-out;
	}

	.navbar-toggler.collapsed~.navbar-collapse {
		transition: left 500ms ease-in-out;
	}

	.nav-link {
		display: inline-flex;
	}

	.rounded-pill {
		transform: scale(0.8);
		margin-left: -0.5rem;
		display: inline-flex;
	}

	.dropdown-menu {
		text-align: center;
	}

	.dropdown-item {
		width: 100%;
		text-align: left;
		font-size: 0.8rem;
	}

	.input-group {
		padding: 4px 4px !important;
	}

	#basic-nav-dropdown {
		padding: 12px 4px 0px 0px;
		text-align: right;
		justify-content: flex-end;
		min-width: unset;
		font-size: 0.8rem;
	}

	.nav-item.dropdown {
		height: 32px !important;
		text-align: right !important;
	}

	.whatsapp-icon {
		margin-top: 10px;
	}

	.banner-preview {
		max-width: inherit !important;
		max-height: unset !important;
	}

	.nav-item.dropdown {
		height: 32px !important;
		text-align: right !important;
	}

	.whatsapp-icon {
		margin-top: 10px;
	}

	.banner-preview {
		max-width: inherit !important;
		max-height: unset !important;
	}

	/* NAVBAR notifications */
}

.centered {
	justify-content: center;
	align-items: center;
}

.anularCero {
	color: var(--background-color);
}

.font-weight-700 {
	font-weight: 700;
}

/* NAVBAR */
.nav-link:focus,
.nav-link:hover {
	text-shadow: 2px 2px 4px #000000;
	color: #e6e6e6 !important;
}

.empty-list-image-preview {
	max-width: 300px;
	max-height: 300px;
}

.banner-preview {
	max-width: 700px;
	max-height: 300px;
}

.product-image-preview {
	padding: 0;
	width: 300px;
	height: 300px;
	margin: auto;
	border: 1px solid black;
	background-repeat: no-repeat;
	background-size: 100% auto;
	background-position: center top;
}

.font-weight-700 {
	font-weight: 700;
}

/* NAVBAR */
.nav-link:focus,
.nav-link:hover {
	text-shadow: 2px 2px 4px #000000;
	color: #e6e6e6 !important;
}

.empty-list-image-preview {
	max-width: 300px;
	max-height: 300px;
}

.banner-preview {
	max-width: 700px;
	max-height: 300px;
}
.w-fit-content {
	width: fit-content;
}

.h-fit-content {
	height: fit-content;
}
.fit-content {
	height: fit-content;
	width: fit-content;
}

.product-image-preview {
	padding: 0;
	width: 300px;
	height: 300px;
	margin: auto;
	border: 1px solid black;
	background-repeat: no-repeat;
	background-size: 100% auto;
	background-position: center top;
}

.container-banner-preview {
	padding: 0;
	margin: auto;
	border: 1px solid black;
	background-repeat: no-repeat;
	background-size: 100% auto;
	background-position: center top;
}

.row:has(.container-banner-preview) {
	margin: 2.5% auto 7.5%;

	.row:has(.product-image-preview) {
		margin: 2.5% auto 7.5%;
	}

	.notifications-container .container {
		max-width: 100vw;
		right: 0 !important;
		transform: none !important;
	}

	.borderLine {
		width: 100%;
		border: var(--primaryColor) 3px solid;
		background-color: var(--primaryColor);
	}

	.cardCalendar {
		height: fit-content;
	}
}

.admin-button {
	background-color: #d7d5d591 !important;
	padding: 6px 12px;
	border-radius: 4px;
	border: none;
	margin: 0;
	&:hover {
		filter: brightness(95%);
	}
	&:focus,
	&:active {
		box-shadow: none !important;
		outline: 0;
	}
	&.dark {
		background-color: #6c757d !important;
	}
}
.no-gutter {
	--bs-gutter-x: 0;
	--bs-gutter-y: 0;
}
.btn {

	&:focus,
	&:active {
		box-shadow: none !important;
		outline: 0 !important;
	}
}