.profile-membership-list {
  width: 100%;
  padding: 10px;
  font-family: Arial, sans-serif;
}

.profile-membership-header {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  font-family: 'Inter', sans-serif;
  font-size: 16px;
    font-weight: 200;
    padding-top: 0.2rem;

    span{
      padding-top: 0.2rem;
    }

}

.back-button {
  cursor: pointer;
  font-size: 20px;
  margin-right: 5px;
  padding-top: 0 !important;
}
/* 
h1 {
  font-size: 18px;
  margin: 0;
} */

.profile-membership-tabs {
  display: inline-flex;
  padding: 2px 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-bottom: 0.6rem;
}

.profile-membership-tab {
  font-family: 'Inter', sans-serif;
  flex: 1;
  border: none;
  background-color: white;
  font-size: 14px;
  cursor: pointer;
  border-radius: 10px;
  height: 25px;
  width: 80px;
  padding: 2px;
  text-align: center;
  justify-content: center;

}

.profile-membership-tab.active {
  background-color: black;
  color: white;

}

.membership-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.membership-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #E8E6E6;
  border-radius: 5px;
  padding: 10px;
}

.card-content {
  display: flex;
  align-items: center;
  gap: 10px;
}

.profile-membership-classes-number {
  display: grid;
  text-align: center;
  background-color: white;
  border-radius: 5px;
  padding: 10px;
  min-width: 70px;
  font-size: 20px;
  strong{
    font-size: 26px;
  }
}

.profile-membership-classes-number span {
  font-size: 12px;
}

.membership-info {
  font-size: 14px;
  color: #555;
  padding-top: 7px;

  p{
    margin-bottom: 8px;
  }
}

.button-container {
  display: flex;
  justify-content: center;
  margin-top: 40px; /* Adjust spacing as needed */
}

.obtener-nueva-button {
  background-color: #ababab;
  color: #000000;
  font-family: "Inter-Light", Helvetica;
  font-size: 16px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: normal;
  margin-bottom: -2.50px;
  margin-top: -4.50px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
  border: none;
  border-radius: 10px;
  padding: 7px 12px;
  cursor: pointer;
}

.obtener-nueva-button:hover {
  background-color: #282828; 
  color: white;
}