.separator {
    display: flex;
    align-items: center;
    text-align: center;
    margin: 0 8rem;
    color: rgba(0, 0, 0, 0.593);
}
.login-container-dialog {
    height: 32rem;
}
.separator::before,
.separator::after {
    content: '';
    flex: 1;
    border-bottom: 1px solid rgba(0, 0, 0, 0.593);
}

.separator:not(:empty)::before {
    margin-right: 1em;
}

.separator:not(:empty)::after {
    margin-left: 1em;
}