.mp {
  color: var(--mp-blue);
  border-color: var(--mp-blue);
}
.mp:hover {
  background-color: var(--mp-blue);
}
.mp a {
  color: var(--mp-blue);
}

.mp:hover #mp-logo {
  filter: invert(0%) sepia(100%) saturate(7456%) hue-rotate(115deg) brightness(108%) contrast(109%);
}
#mp-logo {
  transition: 0.1s;
  margin-left: 0.2rem;
  filter: invert(45%) sepia(22%) saturate(3891%) hue-rotate(168deg) brightness(97%) contrast(101%);
  height: 1.9rem;
}
