
#mercadopagoconfig .form-floating .form-control {
  border: 1px solid #00000057 !important;
  border-bottom-color: #00000057 !important;
  border-radius: 0.18rem;
  border-right:none !important ;
  border-bottom-right-radius: 0% ;
  border-top-right-radius: 0% ;
  height: calc(3.3rem + 1px);
}
.toggle-password-button {
  height: calc(3.3rem + 1px);
  border: 1px solid #00000057 !important;
  border-top-right-radius: 0.18rem;
  border-bottom-right-radius: 0.18rem;
  border-left: none !important;
  position: relative;
  border: none;
  background-color: transparent;
}
.toggle-password-button.input-focused {
  border: 1px solid var(--secondaryColor) !important;
  border-left: none !important;
}

#mercadopagoconfig .form-floating input:focus+label {
  color: var(--secondaryColor);
}

#mercadopagoconfig .form-floating input:focus {
  color: var(--secondaryColor);
  border: 1px solid var(--secondaryColor) !important;
  border-right: none !important;
  box-shadow: none;
}

#mercadopagoconfig .btn.btn-outline-secondary {
  color: var(--btnFontColor);
  border-color: var(--secondaryColor);
  background-color: var(--secondaryColor);
  font-weight: 700;
  width: 6.5rem;
  margin-left: 0.3rem;
  font-size: 0.85rem;
  border-radius: 1rem;
}

#mercadopagoconfig .btn.btn-outline-secondary:hover,
#mercadopagoconfig .btn.btn-outline-secondary:focus {
  transition: .2s;
  outline: none !important;
  box-shadow: none !important;
  filter: brightness(90%);

}


@media screen and (max-width: 576px) {
  .row-appkey-button {
    justify-content: center !important;
  }
}
@media screen and (max-width: 768px) {
  .mercadopagoconfig-container {
    justify-content: center !important;
  }
  #mercadopagoconfig .form-floating {
    width: 85% !important;
  }
  #mercadopagoconfig label {
    font-size: 0.83rem;
    width: 12.5rem;
  }
   .accordion-body:has(> #mercadopagoconfig) { 
    padding: 1rem 0.3rem  !important;
  }
  #mercadopagoconfig .btn.btn-outline-secondary {
    margin-top: 0.2rem;
  }
}