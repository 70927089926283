.user-basic-data {
    --bs-gutter-x: 0;
    display: flex;
    flex-direction: column;
    min-height: fit-content !important;
    height: fit-content !important;
    .profile-photo {
        flex: 1;
        display: flex;
        align-items: flex-end;
        justify-content: center;
        svg {
            font-size: 5em;
            @media screen and (max-height: 768px) {
            font-size: 4em;
                
            }
        }

    }
    figure {
        max-height: 100%;
        display: flex;
        justify-content: center;
        margin: 0;
        padding: 0;
        margin-left: 0.5rem;
        align-items: center;

        img {
            max-height: 100%;
            height: 60px;
            width: auto !important;
            aspect-ratio: 1/1;
        }
    }

    .basic-data {
        flex: 8;
        align-items: flex-start;
        justify-content: flex-end;
        flex-direction: column;
        display: flex;
        min-height: fit-content;
        max-height: 100%; 
        overflow: hidden; 
        padding: 0.6rem;

        h1,
        h2 {
            margin: 0 !important;
            height: fit-content;
        }

        .data-line {
            display: flex;
            align-items: center;
            
            svg {
                font-size: 1.1rem;
                margin-right: .25rem;
                color: rgba(0, 0, 0, 0.648);
            }
            span {
                font-size: .85rem;
                color: rgba(0, 0, 0, 0.819);
                color: rgba(0, 0, 0, 0.648);
            }
        }
    }
}

.button-col {
    max-height: 100%; 
    display: flex; 
    flex: 1;
    align-items: flex-end;
    justify-content: end; 
}

.edit-button {
    background-color: transparent; 
    border: none;
    display: flex; 
    justify-content: end; 
    align-items: center;
    width: 20px; 
    height: 20px; 
    padding: 0;
    margin-bottom: 0.5rem;
    margin-right: 0.4rem;
    cursor: pointer; 
}

.edit-button .icon {
    color: #404040;
    font-size: 100%; 
    width: 100%; 
    height: 100%; 
}