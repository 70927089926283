#admin-exercises-screen {
    h1 {
        width: fit-content;
    }

}
.exercise-editor {
	.form-control {
		margin-bottom:0 !important ;
	}
	.invalid-feedback {
		font-size: .85rem;
		margin: .1rem .75rem;
		display: block;
		color: transparent;	
	}
		.is-invalid~.invalid-feedback,
		.is-invalid~.invalid-tooltip,
		.was-validated :invalid~.invalid-feedback,
		.was-validated :invalid~.invalid-tooltip {
			color: #dc3545;
		}
}
.fade.modal.show:has(.modal-dialog.exercise-editor) {
    display: flex !important;
    justify-content: center;
    align-items: center;
    .modal-dialog.exercise-editor {
        margin: 0 !important;
    }
}
.modal-dialog.exercise-editor {
    .form-control {
        border-radius: 6px;
        margin-bottom: 0.5rem;
        border: 1px solid rgb(222, 226, 230) !important;
    }
}
.row:has(.container-banner-preview) {
	margin: 2.5% auto 7.5%;

	.row:has(.product-image-preview) {
		margin: 2.5% auto 7.5%;
	}

	.notifications-container .container {
		max-width: 100vw;
		right: 0 !important;
		transform: none !important;
	}


	.cardCalendar {
		height: fit-content;
	}
}
