.wod-editor {
    .block-wrapper {
      margin-bottom: 2rem;
      /* temp styles */
      width: 100%;
      height: auto;
      border: 1px solid rgba(0, 0, 0, 0.125);
      border-radius: 8px;
  
      .header {
        margin: auto;
        padding: 1rem;
        width: 100%;
        border-bottom: 1px solid rgba(0, 0, 0, 0.125);
      }
  
      .body {
        padding: 0.5rem;
  
        .wod-data {
          justify-content: start;
        }
      }

      .accordion-block-form .accordion-button{
        color: black;
        background-color: white;
      }
      .accordion-block-form .accordion-button:focus{
        color: black;
        background-color: white;
      }

      .accordion-block-form .accordion-body{
        border-top: none;
     
      }
  
      .block-title {
        border-top: 1px solid white;
        position: relative;
        left: 1rem;
        width: fit-content;
        bottom: 1px;
        font-weight: 500;
        display: flex;
        align-items: center;
        justify-content: space-between; /* Ensure title and button are aligned properly */
        width: 100%; /* Take full width to align button to the right */
  
        span {
          background-color: white;
          padding: 0 0.5rem;
          font-size: 1.5rem;
          position: relative;
          bottom: 1.1rem;
        }
  
      }
  
      .form-field {
        justify-content: center;
        margin-bottom: 0.5rem;
        display: flex;
        align-items: center;
  
        @media screen and (max-width: 576px) {
          justify-content: center;
        }
  
        .left-label,
        .right-label {
          font-weight: 500;
          text-align: center;
          width: fit-content;
          font-size: 0.85rem;
        }
  
        .form-control {
          text-align: center;
          width: 40%;
          margin: 0 0.5rem;
          font-size: 0.85rem;
          padding: 0.5rem 0 !important;
        }
  
        @media screen and (max-width: 576px) {
          .left-label,
          .right-label {
            width: 35%;
          }
          .right-label {
            text-align: start;
          }
          .left-label {
            text-align: end;
          }
          .form-control {
            width: 30%;
          }
        }
      }
  
      .form-floating {
        height: calc(3rem + 2px);
  
        label {
          transform: scale(0.85) translateY(-0.7rem) translateX(0.15rem);
          padding-bottom: 8px;
          height: fit-content;
          font-size: 0.9rem;
        }
  
        &:has(svg) {
          label {
            transform: scale(0.85) translateY(-0.7rem) translateX(-1.1rem);
          }
  
          svg {
            position: relative;
            bottom: 75%;
            left: 90%;
          }
        }
  
        input,
        select {
          height: 100%;
        }
  
        select {
          margin-bottom: 0;
          padding-top: 1.25rem;
          padding-bottom: 0.25rem;
        }
  
        .rbt {
          input {
            padding-top: 1.625rem;
            padding-bottom: 0.625rem;
          }
  
          height: 100%;
        }
      }
    }
  
    .modal-header {
      text-transform: uppercase;
    }
  
    .modal-body {
      height: fit-content;
    }
  
    .labels {
      height: fit-content;
      font-weight: 600;
    }
  
    hr {
      width: 98%;
      color: rgba(0, 0, 0, 0.436);
      margin: 1rem auto;
    }
  
    .form-floating {
      margin-bottom: 0.5rem;
  
      label {
        opacity: 1 !important;
        left: auto;
      }
    }
  
    .floating-typeahead {
      position: relative;
  
      .form-control {
        padding: 1rem 0.75rem;
        height: calc(3.5rem + 2px);
        line-height: 1.25;
      }
  
      label {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        padding: 1rem 0.75rem;
        pointer-events: none;
        border: 1px solid transparent;
        transform-origin: 0 0;
        transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
      }
  
      .form-control:focus ~ label {
        opacity: 0.65;
        transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
      }
    }
  
    .time-details{
      background-color: #e5e5e5;
    }

    .wod-exercises{
      margin-top: 1rem;
    }
  
  }
  
  .read-only-mode {
    margin: 0 !important;
    width: 100% !important;
  }

  .block-form-footer{
    width: 100%;
    display: flex;
    padding: 3;
    align-items: center;
    justify-content: space-between;
    
  }


.invalid-feedback {
  display: block;
  width: 100%;
  margin-top: 0.25rem;
  margin-left: 0.25rem;
  font-size: 0.875em;
  color: #dc3545;
  transition: opacity 0.15s ease-in-out;
}

/* Add these styles to your BlockForm.css */
.invalid-typeahead {
  border-color: #dc3545 !important;
  box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25) !important;
}

.invalid-typeahead:focus {
  border-color: #dc3545 !important;
  box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25) !important;
}

/* Make sure the Typeahead input has transitions for smooth effects */
.rbt-input-main {
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.rbt-input-main.is-invalid:focus {
  border-color: #86b7fe;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}