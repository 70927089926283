.login-prompt {
    display: flex;
    flex-direction: column;
    height: fit-content;
    background-color: white;
    border-radius: 5px;
    padding: 1rem;

    .body,
    .header,
    .footer {
        width: 100%;

    }

    .header,
    .footer {
        flex: 1
    }

    .footer {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;

        span {
            font-size: 0.9rem;
            font-weight: 500;
        }

        a {
            color: black;
            text-decoration: none;
            font-size: 0.9rem;
            font-weight: 600;
        }

        .forgot-password {
            font-size: 0.8rem;
            font-weight: 400;
            text-align: center;
        }
    }

    .body {
        flex: 3;
    }

    form {
        width: 100%;
    }

    h2 {
        width: 100%;
        text-align: center;
        font-size: 1.5rem;
        font-weight: 600;
    }

    button {
        border: none;
        background-color: rgb(239, 239, 239);
        color: black;
        height: 2.5rem;
        font-size: 0.88rem;
        font-weight: 600;
        padding: 0 1rem;
        border-radius: 25px;
    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    textarea:-webkit-autofill,
    textarea:-webkit-autofill:hover,
    textarea:-webkit-autofill:focus,
    select:-webkit-autofill,
    select:-webkit-autofill:hover,
    select:-webkit-autofill:focus {
        -webkit-box-shadow: 0 0 0px 1000px #ffffff inset !important;
    }

    .input-group {
        height: 3rem;

        span {
            svg {
                font-size: 1.15rem;

            }
        }

        input,
        span {
            border-radius: 25px;
            height: 100%;
            background-color: transparent;
            font-weight: 350;
            border: 1px solid rgba(0, 0, 0, 0.156) !important;
            font-size: 0.85rem;
        }

        input::placeholder {
            font-size: 0.85rem;
            font-weight: 500;
        }

        span {
            height: 100%;
        }
    }
}