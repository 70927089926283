#payment-failure-screen {
  margin: 10rem;
  margin-bottom: 0;
}
#payment-failure-screen .fas.fa-spinner {
  color: rgba(0, 0, 0, 0.429);
    animation: elastic-spin 1s infinite ease;
    font-size: 3rem;
}
@keyframes elastic-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(720deg); }
}
