.client-navbar-options {
    a.focused {
        background-color: #ABABAB;
    }

    .nav-option {
        cursor: pointer;
        span, a {
            text-decoration: none;
        }
    }
    @media screen and (min-width: 768px) {
        .nav-toggler {
            display: none;
        }
    }
}