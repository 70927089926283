.datepicker {
    width: 100%;
    max-width: 700px;
    padding: 0.25rem;
    padding-bottom: 0.5rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    h2 {
        padding: 0 1.2rem;
        text-align: start;
        width: fit-content;
        height: fit-content;
        font-size: 1.7rem;
        text-transform: capitalize;
        font-weight: 300;
    }

    .datepicker-days {
        width: calc(100% - 150px);
        display: flex;
        justify-content: space-between;
    }

    @media screen and (max-width: 768px) {
        & {
            width: 100%;
        }
        .datepicker-days {
            width: 100%;
            justify-content: center;
        }
        h2 {
            width: 100%;
            font-size: 1.5rem;
        }

    }
    @media screen and (min-width: 576px) {
        h2 {
            font-size: 1.5rem;
        }

    }

    @media screen and (max-width: 576px) {
        h2 {
            font-size: 1.3rem;
        }

        & {
            max-width: 90%;
        }

    }


    @media screen and (max-width: 376px) {
        h2 {
            font-size: 1.1rem;
            padding: 0 .5rem;
        }

        .day-button {
            padding: .3rem;
        }

        .name {
            font-size: .65rem;
        }

        .date {
            font-size: .95rem;
        }

    }
}