
.tab-loading-overlay {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    @media screen and (min-width: 992px) {
        &.admin {
            span {
                position: relative;
                bottom: 5rem;
            }
            width: calc(100% - 207px);
            height: calc(100% - 50px);
        }
    }
    @media screen and (max-width: 992px) {
        &.admin {
            height: calc(100% - 50px);
        }
    }
}
