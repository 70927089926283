.btn-plus {
	background-color: white;
	border: 1px solid var(--secondaryColor) !important;
	color: var(--secondaryColor);
	width: 1.4rem;
	line-height: 1.15rem;
	height: 1.3rem;
	border-radius: 50%;
}

.btn-plus:hover,
.btn-plus:active,
.btn-plus:focus {
	margin: 0;
}

.btn-plus {
	border: 1px solid var(--secondaryColor) !important;
	padding: 0;
	outline: none !important;
}
