.stepper-wrapper {
  display: flex;
  contain: inline-size;
  justify-content: space-around;
}
.step-circle {
  aspect-ratio: 1/1;
  width: 60%;
  background-color: var(--primaryColor);
  border-radius: 50%;
  transition: 0.5s;;
}
.stepper-item {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: .9;
  .step-name {
    color: rgba(0, 0, 0, 0.605);
    font-size: .75rem;
  }
  &::before {
    position: absolute;
    content: '';
    border-bottom: 1px solid #ccc;
    width: 100%;
    top: 36%;
    left: -50%;
    z-index: 2;
  }
  &::after {
    position: absolute;
    content: '';
    border-bottom: 1px solid #ccc;
    width: 100%;
    top: 36%;
    left: 50%;
    z-index: 2;
    @media screen and (max-width: 300px) {
      & {
        top: 5%;
      }
    }
  }
  &:first-child::before {
    content: none;
  }
  
  &:last-child::after {
    content: none;
  }

  .step-counter {
    position: relative;
    z-index: 6;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 50px;
    width: 60%;
    aspect-ratio: 1/1;
    border-radius: 50%;
    background: var(--background-color);
    border: 1px solid #ccc;
    @media screen and (max-width: 376px) {
      width: 50%;
      
    }
    svg {
      color: white;
      font-size: 1.5rem;
    }
  }
  &.active {
    .step-counter {
      font-weight: bold;
      border: 2px solid var(--primaryColor);
      transition: 0.5s;
    }
    .step-name {
      color: var(--primaryColor);
    }
  }
  &.completed {
    .step-counter {
      background-color: var(--primaryColor);
      border: 2px solid var(--primaryColor);
      transition: 0.1s;
      
    }
    &::after {
      position: absolute;
      content: '';
      border-bottom: 2px solid var(--primaryColor);
      width: 100%;
      top: 36%;
      left: 50%;
      z-index: 3;

   }
  }

}
@media screen and (max-width: 300px) {
  .stepper-item::after, .stepper-item::before,
   .stepper-item.completed::after, .stepper-item.completed::before{
    top: 25%;
  }
}


