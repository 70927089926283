.previous-page-row {
    width: 100%;
    span {
        cursor: pointer;
        display: flex;
        align-items: center;
        svg {
            font-size: 1.5rem;
        }
        font-weight: 300;
        font-size: 1.2rem;
    }
    margin-top: -1.2rem;
    margin-bottom: 1.1rem;
}