#side-cart-overlay {
 position: fixed;
 display: none;
 top: 0;
 right: 0;
 left: 0;
 bottom: 0;
 width: 100vw; 
 height: 100vh;
 z-index: 1000;
 background-color: #00000031;
 transition: 0.25s;
 /* transition: right 0.3s ease-in-out; */
}
#side-cart {
 z-index: 1001;
 position: fixed;
  background-color: white;
 height: 100%;
 top: 0;
 right: 0;
 bottom: 0;
 left: 100vw;
 transform: translateX(0px);
 width: 550px;
 transition: 0.35s ease-out;
 &.show {
  right: 0;
  transform: translateX(-550px);
  transition: 0.35s ease-out;
  box-shadow: -3px 0px 2px 0px rgba(60, 64, 67, 0.15);
 }
}

.my-cart-title{
  color: #000;
  text-transform: uppercase !important;
  font-family: Inter, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-left: 0.2rem;
}

.cart-line{
  width: 100%;
  border-bottom: 1px solid #8D8D8D;
  position: relative;
  margin: 0px;
  padding: 0px;
  }


@media screen and (max-width: 762px) {
    #side-cart {
    width: 100vw;
    &.show {
        right: 0;
        transform: translateX(-100vw);
    }
    }
    
}
#side-cart {
 & > * {
  display: flex;
 }
 .header {
  justify-content:space-between;
  align-items: center;
  background-color: white;
  height: 60px;
  padding: 1.5rem 1.5rem;
  &.scrolled {
    box-shadow: 0px -1px 8px 0px #0000001a;
  }
  margin-top: 10px;
  h1 {
   font-size: 1.4rem;
   font-weight: 400;
  }
  svg {
   font-size: 2.5rem;
  }
 }
 .body {
  height: calc(100% - 160px);
  overflow-y: scroll;
  background-color: white;
  flex-direction: column;

 }
 .cart-footer {
  height: 100px;
  background-color: white;
  display: flex;
  box-shadow: 0px -1px 8px 0px #0000001a;
  justify-content: space-around;
  align-items: center;
  span {
   font-size: 1.2rem;
   font-weight: 300;
  }
  /* padding: 1rem; */
 }
 .go-back-btn {
  background-color: transparent;
  text-decoration: underline;
 }

 .complete-purchase-btn {
  border: none;
  height: 40px;
  border-radius: 4px;
  color: white;
  font-weight: 500;
  background-color: var(--primaryColor);
  transition: 0.1s;
  &:hover {
   filter: brightness(95%);
   transition: 0.1s;
  }
 }
}
