@keyframes TabSelection {
    from {
        background-color: rgba(201, 201, 201, 0.228);
        ;
    }

    to {
        background-color: white;
    }
}
.tab-container {
    display: flex;
    overflow-x: hidden;
    flex-wrap: wrap;

    .tabs {
        width: 100%;
        display: flex;

        .tab {
            flex: 1;
            text-transform: capitalize;
            background-color: white;
            border: none;
            color: rgb(102, 102, 102);
            padding: .85rem;
            font-weight: 500;
            font-size: 1rem;
            transition: 0.5s;
            @media screen and (max-width: 376px) {
                padding: .5rem;
                font-size: .85rem;
            }

            &:hover {
                cursor: pointer;
            }

            &:focus {
            
            animation: TabSelection 1s ease-in-out forwards;
            }
        }
    }



    .bottom-bar {
        width: 100%;
        height: 5px;
        background-color: rgba(102, 102, 102, 0.539);
    }

    .bottom-bar-fill {
        transition: transform 0.25s ease;
        height: 5px;
        background-color: rgb(0, 124, 181);
    }
    .tab-content {
        width: 100%;
        display: flex;
        flex-direction: row;
        position: relative;
        transition: transform 0.3s ease-out;
        .content {
            padding: 0.75rem;
        }
        & > * {
            width: 100%;
            flex-shrink: 0;
            transition: transform 0.5s ease-in-out;
        }
    }

}



