.loading-overlay {
    position: fixed;
    inset: 0px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    background-color: rgba(33, 37, 41, 0.2);
    z-index: 5001;

    .loading-spinner {
        display: block;
        height: 42px;
        width: 42px;
        color: var(--primaryColor);
        border-radius: 50%;
        border-width: 2px;
        border-style: solid;
        border-color: transparent var(--primaryColor) var(--primaryColor);
        border-image: initial;
        box-sizing: border-box;
        animation: rotate-infinite infinite 1000ms linear;
    }
}

@media screen and (min-width: 992px) {
    #root:has(.admin-con) {
        .loading-overlay {
            margin-left: 13rem;
        }
    }
}