body {
	margin: auto;
	font-family:
		Inter var,
		ui-sans-serif,
		system-ui,
		-apple-system,
		BlinkMacSystemFont,
		Segoe UI,
		Roboto,
		Helvetica Neue,
		Arial,
		Noto Sans,
		sans-serif,
		Apple Color Emoji,
		Segoe UI Emoji,
		Segoe UI Symbol,
		Noto Color emoji;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
.site-container {
	min-height: 100vh;
}
/* header */
header {
	display: flex;
	align-items: flex-end;
	justify-content: flex-start;
}
header a {
	color: white;
	text-decoration: none;
	font-weight: bold;
	display: inline-block;
	align-self: center;
	padding-left: 0.2rem;
}
header a:hover {
	color: white;
}
/* header img {
  max-height: 2rem;
} */
/* main */

/* products */
.products {
	display: flex;
	justify-content: flex-start;
	flex-wrap: wrap;
	padding: 1rem 2rem 4rem 2rem;
}
.product {
	border: 1px solid #dddddd;
}
.product img {
	height: 65%;
	width: 100%;
	max-width: 400px;
}
.product a {
	/* font-size: 0.8rem; */
	font-size: 75%;
	text-decoration: none;
	color: black;
	margin-bottom: 0.2rem;
	height: 25%;
}

/* }
.product-info {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 1rem;
  height: 35%;
} */

.products .card-title .h5 {
	font-size: 1rem !important;
}
.products .card-body:hover {
	background-color: #dddddd59;
	transition: 0.5s;
}
/* .product-info:hover {
  background-color: #dddddd59;
  transition: 0.5s;

}
.product-info p:nth-of-type(1){
  margin-left: 0.5rem; 
  margin-right: 0;
  margin-bottom: 0;
  font-weight: bolder;
}
.product-price {
  margin-top: 0;
  width: 100%;
  font-size: 0.8rem;
  height: 33.33%;
  display: block;
  margin-bottom: 0.2rem;

} */
.rating {
	width: 100%;
	font-size: 0.8rem;
	display: block;
}
.fa-star:before {
	content: '\f005';
	color: darkorange;
}
/* .rating, .product-price, .product button{
  margin-left: 0.5rem;
  height: 23%;
  margin-bottom: 0.2rem;
  margin-top: 0;
} */

.img-large {
	max-width: 100%;
}
.img-thumbnail {
	height: 80px;
}
.small-container {
	max-width: 600px;
}
.checkout-steps > div {
	border-bottom: 0.2rem solid #a0a0a0;
	color: #a0a0a0;
}
.checkout-steps > div.active {
	border-bottom: 0.2rem solid #f08000;
	color: var(--secondaryColor);
}
.side-navbar {
	width: 240px;
	height: 100%;
	position: absolute;
	left: -300px;
	transition: 0.5s;
}
.site-container {
	transition: 0.4s;
}
.active-cont {
	margin-left: 240px;
}
.active-nav {
	left: 0;
}
