#payment-success-screen {
  margin: 10rem;
  margin-bottom: 0;
}
.payment-status-message {
  background-color: rgb(215, 147, 20);
  color: var(--navFontColor);
  font-weight: 500;
  font-size: 1.3rem;
  border-radius: 0.5rem;
  padding: 0.5rem 1rem;
}
#payment-success-screen .fas.fa-spinner {
  color: var(--titlesColor);
    animation: elastic-spin 1s infinite ease;
    font-size: 3rem;
}
.status-container {
  background-color: white;
}
@keyframes elastic-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(720deg); }
}