
#admin-config-screen a {
  text-decoration: none;
  color: black;
}

#admin-config-screen h1 {
  margin-bottom: 2.5%;
}
.accordion-header button {
  font-weight: 500;
  padding: 0.5rem;
}


.accordion-item:first-of-type {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.accordion-item:last-of-type {
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
@media screen and (max-width: 768px) {
  #admin-config-screen {
    padding-left: 0;
    padding-right: 0;
  }
  #admin-config-screen h1 {
    padding-left: 0.6rem;
  }
}
