.update-prices-modal thead {
    border-top: none;
  }
  
  .update-prices-modal th {
    padding: .2rem;
    font-size: .92rem;
    font-weight: 500;
    border-top: none;
  }
  
  @media screen and (max-width: 768px) {
    .update-prices-modal th {
      font-size: .85rem;
    }
  }
  
  .update-prices-modal tr {
    border: 1px solid #ccc;
  }
  
  .update-prices-modal tr td {
    font-size: .9rem;
    border-right: 1px solid #ccc;
    padding: .2rem;
  }
  
  @media screen and (max-width: 768px) {
    .update-prices-modal tr td {
      font-size: .8rem;
    }
  }
  
  .update-prices-modal .modal-footer {
    display: flex !important;
    justify-content: space-between;
  }
  
  .update-prices-modal .modal-footer button {
    margin: 0;
  }
  
  /* .update-prices-modal .modal-footer .btn-primary {
    background-color: #E9F9F4;
  }
  
  .update-prices-modal .modal-footer .btn-secondary {
    background-color: #FFF2F2;
  } */
  
  .toggle-button-group {
    display: inline-flex;
    border: 1px solid #ccc;
    border-radius: 5px;
    overflow: hidden;
    margin-bottom: 10px;
    height: 2rem;
  }
  
  .toggle-button {
    margin: 0;
    display: flex;
    align-items: center;
    font-size: .9rem;
    padding: 10px 15px;
    border: none;
    background: #e6e6e6;
    cursor: pointer;
    font-weight: bold;
    transition: background 0.3s;
  }
  
  .toggle-button.active {
    background: #ccc;
    color: #000;
  }
  
  .toggle-button:not(.active) {
    background: #f9f9f9;
    color: #aaa;
  }
  
  .custom-input {
    /* height: 40px !important; */
    padding: 10px;
    font-size: 1rem;
    border-bottom: 1px solid #ccc !important;
    width: 85%;
    text-align: center;
    margin-bottom: .2rem;
    font-size: .88rem;
  }
  
  @media screen and (max-width: 768px) {
    .custom-input, .custom-input::placeholder {
      font-size: .9rem;
    }
  }
  
  .custom-input:focus::placeholder {
    color: transparent;
  }
  
  .apply-modification {
    height: 2rem !important;
    font-size: .85rem;
    padding: .3rem .8rem !important;
    margin: .5rem 0;
    color: rgba(0, 0, 0, 0.671) !important;
    background-color: #e3e3e3 !important;
    transition: .1s;
  }
  
  .apply-modification:hover,
  .apply-modification:active,
  .apply-modification:focus {
    box-shadow: none !important;
    outline: none !important;
    margin: .5rem 0;
    transition: .1s;
    color: rgba(0, 0, 0, 0.671) !important;
    background-color: #e3e3e3 !important;
  }
  
  .switch-container {
    display: flex !important;
    justify-content: space-between !important;
  }
  